import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { WorkPlatformService } from '@/sevices/index';
let homeLatelyApp = class homeLatelyApp extends Vue {
    constructor() {
        super(...arguments);
        this.searchTxt = '';
        this.timer = '';
        this.list = [];
        this.loading = false;
    }
    get total() {
        return this.list.length;
    }
    /**
     * 获取应用列表
     */
    async getAppList() {
        const params = {
            keywork: this.searchTxt,
            page: 1,
            size: 40,
        };
        const res = await WorkPlatformService.getAppList(params);
        if (res.success) {
            this.list = res.data;
        }
        else {
            this.list = [];
        }
        this.loading = true;
    }
    /**
     * 搜索：防抖
     * 删除搜索条件后不进行搜索并清空应用列表
     */
    async searchChange(e, r) {
        console.log(e, r);
        this.loading = false;
        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
            if (this.searchTxt) {
                this.getAppList();
            }
            else {
                this.list = [];
            }
        }, 800);
    }
    cancel() {
        this.$router.go(-1);
    }
};
homeLatelyApp = __decorate([
    Component({
        name: 'homeLatelyApp',
        components: {
            AppItem: () => import('./app-item'),
            searchBar: () => import('../search-bar'),
        },
    })
], homeLatelyApp);
export default homeLatelyApp;
